export const PROJECT_LIQUIDITY_REGISTER_REQUEST = 'PROJECT_LIQUIDITY_REGISTER_REQUEST'
export const PROJECT_LIQUIDITY_REGISTER_SUCCESS = 'PROJECT_LIQUIDITY_REGISTER_SUCCESS'
export const PROJECT_LIQUIDITY_REGISTER_FAIL = 'PROJECT_LIQUIDITY_REGISTER_FAIL'
export const PROJECT_LIQUIDITY_REGISTER_RESET = 'PROJECT_LIQUIDITY_REGISTER_RESET'

export const PROJECT_LIQUIDITY_LIST_REQUEST = 'PROJECT_LIQUIDITY_LIST_REQUEST'
export const PROJECT_LIQUIDITY_LIST_SUCCESS = 'PROJECT_LIQUIDITY_LIST_SUCCESS'
export const PROJECT_LIQUIDITY_LIST_FAIL = 'PROJECT_LIQUIDITY_LIST_FAIL'
export const PROJECT_LIQUIDITY_LIST_RESET = 'PROJECT_LIQUIDITY_LIST_RESET'

export const PROJECT_LIQUIDITY_UPDATE_REQUEST = 'PROJECT_LIQUIDITY_UPDATE_REQUEST'
export const PROJECT_LIQUIDITY_UPDATE_SUCCESS = 'PROJECT_LIQUIDITY_UPDATE_SUCCESS'
export const PROJECT_LIQUIDITY_UPDATE_FAIL = 'PROJECT_LIQUIDITY_UPDATE_FAIL'
export const PROJECT_LIQUIDITY_UPDATE_RESET = 'PROJECT_LIQUIDITY_UPDATE_RESET'

export const REBUILD_PROJECT_LIQUIDITY_CSV_REQUEST = 'REBUILD_PROJECT_LIQUIDITY_CSV_REQUEST'
export const REBUILD_PROJECT_LIQUIDITY_CSV_SUCCESS = 'REBUILD_PROJECT_LIQUIDITY_CSV_SUCCESS'
export const REBUILD_PROJECT_LIQUIDITY_CSV_FAIL = 'REBUILD_PROJECT_LIQUIDITY_CSV_FAIL'
export const REBUILD_PROJECT_LIQUIDITY_CSV_RESET = 'REBUILD_PROJECT_LIQUIDITY_CSV_RESET'

export const PROJECT_LIQUIDITY_LIQUIDATE_REQUEST = 'PROJECT_LIQUIDITY_LIQUIDATE_REQUEST'
export const PROJECT_LIQUIDITY_LIQUIDATE_SUCCESS = 'PROJECT_LIQUIDITY_LIQUIDATE_SUCCESS'
export const PROJECT_LIQUIDITY_LIQUIDATE_FAIL = 'PROJECT_LIQUIDITY_LIQUIDATE_FAIL'
export const PROJECT_LIQUIDITY_LIQUIDATE_RESET = 'PROJECT_LIQUIDITY_LIQUIDATE_RESET'

export const PROJECT_LIQUIDITY_LIQUIDATED_REQUEST = 'PROJECT_LIQUIDITY_LIQUIDATED_REQUEST'
export const PROJECT_LIQUIDITY_LIQUIDATED_SUCCESS = 'PROJECT_LIQUIDITY_LIQUIDATED_SUCCESS'
export const PROJECT_LIQUIDITY_LIQUIDATED_FAIL = 'PROJECT_LIQUIDITY_LIQUIDATED_FAIL'
export const PROJECT_LIQUIDITY_LIQUIDATED_RESET = 'PROJECT_LIQUIDITY_LIQUIDATED_RESET'

export const PDL_REGISTER_BY_INVESTMENT_REQUEST = 'PDL_REGISTER_BY_INVESTMENT_REQUEST'
export const PDL_REGISTER_BY_INVESTMENT_SUCCESS = 'PDL_REGISTER_BY_INVESTMENT_SUCCESS'
export const PDL_REGISTER_BY_INVESTMENT_FAIL = 'PDL_REGISTER_BY_INVESTMENT_FAIL'
export const PDL_REGISTER_BY_INVESTMENT_RESET = 'PDL_REGISTER_BY_INVESTMENT_RESET'

export const PROJECT_LIQUIDITY_DELETE_REQUEST = 'PROJECT_LIQUIDITY_DELETE_REQUEST'
export const PROJECT_LIQUIDITY_DELETE_SUCCESS = 'PROJECT_LIQUIDITY_DELETE_SUCCESS'
export const PROJECT_LIQUIDITY_DELETE_FAIL = 'PROJECT_LIQUIDITY_DELETE_FAIL'
export const PROJECT_LIQUIDITY_DELETE_RESET = 'PROJECT_LIQUIDITY_DELETE_RESET'

export const PROJECT_LIQUIDITY_DRAFT_UPDATE_REQUEST = 'PROJECT_LIQUIDITY_DRAFT_UPDATE_REQUEST'
export const PROJECT_LIQUIDITY_DRAFT_UPDATE_SUCCESS = 'PROJECT_LIQUIDITY_DRAFT_UPDATE_SUCCESS'
export const PROJECT_LIQUIDITY_DRAFT_UPDATE_FAIL = 'PROJECT_LIQUIDITY_DRAFT_UPDATE_FAIL'
export const PROJECT_LIQUIDITY_DRAFT_UPDATE_RESET = 'PROJECT_LIQUIDITY_DRAFT_UPDATE_RESET'

export const PDL_REGISTER_REINVESTMENT_REQUEST = 'PDL_REGISTER_REINVESTMENT_REQUEST'
export const PDL_REGISTER_REINVESTMENT_SUCCESS = 'PDL_REGISTER_REINVESTMENT_SUCCESS'
export const PDL_REGISTER_REINVESTMENT_FAIL = 'PDL_REGISTER_REINVESTMENT_FAIL'
export const PDL_REGISTER_REINVESTMENT_RESET = 'PDL_REGISTER_REINVESTMENT_RESET'

export const PDL_REGISTER_REINVEST_INVESTMENTS_REQUEST = 'PDL_REGISTER_REINVEST_INVESTMENTS_REQUEST'
export const PDL_REGISTER_REINVEST_INVESTMENTS_SUCCESS = 'PDL_REGISTER_REINVEST_INVESTMENTS_SUCCESS'
export const PDL_REGISTER_REINVEST_INVESTMENTS_FAIL = 'PDL_REGISTER_REINVEST_INVESTMENTS_FAIL'
export const PDL_REGISTER_REINVEST_INVESTMENTS_RESET = 'PDL_REGISTER_REINVEST_INVESTMENTS_RESET'

export const GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_REQUEST = 'GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_REQUEST'
export const GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_SUCCESS = 'GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_SUCCESS'
export const GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_FAIL = 'GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_FAIL'
export const GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_RESET = 'GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_RESET'

export const GET_RETENTION_CERTIFICATE_BY_PDL_REQUEST = 'GET_RETENTION_CERTIFICATE_BY_PDL_REQUEST'
export const GET_RETENTION_CERTIFICATE_BY_PDL_SUCCESS = 'GET_RETENTION_CERTIFICATE_BY_PDL_SUCCESS'
export const GET_RETENTION_CERTIFICATE_BY_PDL_FAIL = 'GET_RETENTION_CERTIFICATE_BY_PDL_FAIL'
export const GET_RETENTION_CERTIFICATE_BY_PDL_RESET = 'GET_RETENTION_CERTIFICATE_BY_PDL_RESET'