import * as types from '../constants/projectLiquidityConstants'

export const projectLiquidityRegisterReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_LIQUIDITY_REGISTER_REQUEST:
			return { loadingProjectLiquidityRegister: true }
		case types.PROJECT_LIQUIDITY_REGISTER_SUCCESS:
			return {
				loadingProjectLiquidityRegister: false,
				successProjectLiquidityRegister: true,
				projectLiquidity: action.payload,
			}
		case types.PROJECT_LIQUIDITY_REGISTER_FAIL:
			return {
				loadingProjectLiquidityRegister: false,
				errorProjectLiquidityRegister: action.payload,
			}
		case types.PROJECT_LIQUIDITY_REGISTER_RESET:
			return {}
		default:
			return state
	}
}

export const projectLiquidityLiquidateReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_LIQUIDITY_LIQUIDATE_REQUEST:
			return { loadingProjectLiquidityLiquidate: true }
		case types.PROJECT_LIQUIDITY_LIQUIDATE_SUCCESS:
			return {
				loadingProjectLiquidityLiquidate: false,
				successProjectLiquidityLiquidate: true,
				projectLiquidityliquidated: action.payload,
			}
		case types.PROJECT_LIQUIDITY_LIQUIDATE_FAIL:
			return {
				loadingProjectLiquidityLiquidate: false,
				errorProjectLiquidityLiquidate: action.payload,
			}
		case types.PROJECT_LIQUIDITY_LIQUIDATE_RESET:
			return {}
		default:
			return state
	}
}

export const projectLiquidityLiquidatedReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_LIQUIDITY_LIQUIDATED_REQUEST:
			return { loadingProjectLiquidityLiquidatedList: true }
		case types.PROJECT_LIQUIDITY_LIQUIDATED_SUCCESS:
			return {
				loadingProjectLiquidityLiquidatedList: false,
				successProjectLiquidityLiquidatedList: true,
				projectLiquidityliquidatedList: action.payload,
			}
		case types.PROJECT_LIQUIDITY_LIQUIDATED_FAIL:
			return {
				loadingProjectLiquidityLiquidatedList: false,
				errorProjectLiquidityLiquidatedList: action.payload,
			}
		case types.PROJECT_LIQUIDITY_LIQUIDATED_RESET:
			return {}
		default:
			return state
	}
}

export const projectLiquidityListReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_LIQUIDITY_LIST_REQUEST:
			return { loadingProjectLiquidityList: true }
		case types.PROJECT_LIQUIDITY_LIST_SUCCESS:
			return {
				loadingProjectLiquidityList: false,
				successProjectLiquidityList: true,
				projectsLiquiditiesList: action.payload,
			}
		case types.PROJECT_LIQUIDITY_LIST_FAIL:
			return {
				loadingProjectLiquidityList: false,
				errorProjectLiquidityList: action.payload,
			}
		case types.PROJECT_LIQUIDITY_LIST_RESET:
			return {}
		default:
			return state
	}
}

export const projectLiquidityUpdateReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_LIQUIDITY_UPDATE_REQUEST:
			return { loadingProjectLiquidityUpdate: true }
		case types.PROJECT_LIQUIDITY_UPDATE_SUCCESS:
			return {
				loadingProjectLiquidityUpdate: false,
				successProjectLiquidityUpdate: true,
				projectLiquidityUpdated: action.payload,
			}
		case types.PROJECT_LIQUIDITY_UPDATE_FAIL:
			return {
				loadingProjectLiquidityUpdate: false,
				errorProjectLiquidityUpdate: action.payload,
			}
		case types.PROJECT_LIQUIDITY_UPDATE_RESET:
			return {}
		default:
			return state
	}
}

export const pdlRegisterByInvestmentReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PDL_REGISTER_BY_INVESTMENT_REQUEST:
			return { loadingPdlRegisterByInvestment: true }
		case types.PDL_REGISTER_BY_INVESTMENT_SUCCESS:
			return {
				loadingPdlRegisterByInvestment: false,
				successPdlRegisterByInvestment: true,
				pdlRegisteredByInvestment: action.payload,
			}
		case types.PDL_REGISTER_BY_INVESTMENT_FAIL:
			return {
				loadingPdlRegisterByInvestment: false,
				errorPdlRegisterByInvestment: action.payload,
			}
		case types.PDL_REGISTER_BY_INVESTMENT_RESET:
			return {}
		default:
			return state
	}
}

export const deleteProjectLiquidityReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_LIQUIDITY_DELETE_REQUEST:
			return { loadingProjectLiquidityDelete: true }
		case types.PROJECT_LIQUIDITY_DELETE_SUCCESS:
			return {
				loadingProjectLiquidityDelete: false,
				successProjectLiquidityDelete: true,
				projectLiquidityDeleted: action.payload,
			}
		case types.PROJECT_LIQUIDITY_DELETE_FAIL:
			return {
				loadingProjectLiquidityDelete: false,
				errorProjectLiquidityDelete: action.payload,
			}
		case types.PROJECT_LIQUIDITY_DELETE_RESET:
			return {}
		default:
			return state
	}
}

export const updatePdlDraftFieldsReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PROJECT_LIQUIDITY_DRAFT_UPDATE_REQUEST:
			return { loadingPdlUpdateDraftFields: true }
		case types.PROJECT_LIQUIDITY_DRAFT_UPDATE_SUCCESS:
			return {
				loadingPdlUpdateDraftFields: false,
				successPdlUpdateDraftFields: true,
				pdlUpdateDraftFields: action.payload,
			}
		case types.PROJECT_LIQUIDITY_DRAFT_UPDATE_FAIL:
			return {
				loadingPdlUpdateDraftFields: false,
				errorPdlUpdateDraftFields: action.payload,
			}
		case types.PROJECT_LIQUIDITY_DRAFT_UPDATE_RESET:
			return {}
		default:
			return state
	}
}

export const pdlRegisterReinvestmentReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PDL_REGISTER_REINVESTMENT_REQUEST:
			return { loadingPdlRegisterReinvestment: true }
		case types.PDL_REGISTER_REINVESTMENT_SUCCESS:
			return {
				loadingPdlRegisterReinvestment: false,
				successPdlRegisterReinvestment: true,
				pdlRegisteredReinvestment: action.payload,
			}
		case types.PDL_REGISTER_REINVESTMENT_FAIL:
			return {
				loadingPdlRegisterReinvestment: false,
				errorPdlRegisterReinvestment: action.payload,
			}
		case types.PDL_REGISTER_REINVESTMENT_RESET:
			return {}
		default:
			return state
	}
}

export const registerReinvestPdlInvestmentsReducer = (state = {}, action) => {
	switch (action.type) {
		case types.PDL_REGISTER_REINVEST_INVESTMENTS_REQUEST:
			return { loadingPdlRegisterReinvestPdlInvestments: true }
		case types.PDL_REGISTER_REINVEST_INVESTMENTS_SUCCESS:
			return {
				loadingPdlRegisterReinvestPdlInvestments: false,
				successPdlRegisterReinvestPdlInvestments: true,
				pdlRegisteredReinvestPdlInvestments: action.payload,
			}
		case types.PDL_REGISTER_REINVEST_INVESTMENTS_FAIL:
			return {
				loadingPdlRegisterReinvestPdlInvestments: false,
				errorPdlRegisterReinvestPdlInvestments: action.payload,
			}
		case types.PDL_REGISTER_REINVEST_INVESTMENTS_RESET:
			return {}
		default:
			return state
	}
}

export const contractsInfoToDownloadPDLReducer = (state = {}, action) => {
	switch (action.type) {
		case types.GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_REQUEST:
			return { loadingContractsInfoToDownloadPDL: true }
		case types.GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_SUCCESS:
			return {
				loadingContractsInfoToDownloadPDL: false,
				successContractsInfoToDownloadPDL: true,
				contractsInfoToDownloadPDL: action.payload,
			}
		case types.GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_FAIL:
			return {
				loadingContractsInfoToDownloadPDL: false,
				errorContractsInfoToDownloadPDL: action.payload,
			}
		case types.GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_RESET:
			return {}
		default:
			return state
	}
}

export const rebuildProjectLiquidityReducer = (state = {}, action) => {
	switch (action.type) {
		case types.REBUILD_PROJECT_LIQUIDITY_CSV_REQUEST:
			return { loadingRebuildProjectLiquidity: true }
		case types.REBUILD_PROJECT_LIQUIDITY_CSV_SUCCESS:
			return {
				loadingRebuildProjectLiquidity: false,
				successRebuildProjectLiquidity: true,
				rebuildProjectLiquidity: action.payload,
			}
		case types.REBUILD_PROJECT_LIQUIDITY_CSV_FAIL:
			return {
				loadingRebuildProjectLiquidity: false,
				errorRebuildProjectLiquidity: action.payload,
			}
		case types.REBUILD_PROJECT_LIQUIDITY_CSV_RESET:
			return {}
		default:
			return state
	}
}

export const retentionCertificateByPDLIdReducer = (state = {}, action) => {
	switch (action.type) {
		case types.GET_RETENTION_CERTIFICATE_BY_PDL_REQUEST:
			return { loadingRetentionCertificateByPdlId: true }
		case types.GET_RETENTION_CERTIFICATE_BY_PDL_SUCCESS:
			return {
				loadingRetentionCertificateByPdlId: false,
				successRetentionCertificateByPdlId: true,
				retentionCertificate: action.payload,
			}
		case types.GET_RETENTION_CERTIFICATE_BY_PDL_FAIL:
			return {
				loadingRetentionCertificateByPdlId: false,
				errorRetentionCertificateByPdlId: action.payload,
			}
		case types.GET_RETENTION_CERTIFICATE_BY_PDL_RESET:
			return {}
		default:
			return state
	}
}